// import entries from './draw-ketchup-entries.json'
import React, { useState } from 'react';

const Gallery = () => {
  const entries = []

  const size = entries.length
  const perPage = 10
  const leftover = size % perPage
  const pageCount = (size - leftover) / perPage + (leftover? 1 : 0)
  const paginationLimit = 10

  const [currentPage, setCurrentPage] = useState(1)
  const [pageStart, setPageStart] = useState(1)
  
  let pages = []
  for (let i = 0; i < (pageCount > paginationLimit? paginationLimit : pageCount); i++) {
    if (i + pageStart <= pageCount) pages[i] = i + pageStart
  }

  const selectedEntries = [...entries].splice(perPage * (currentPage-1), perPage)

  const changePage = num => {
    if (num > (pageStart - 1) + (paginationLimit - 2)) {
      setPageStart(pageStart + 1)
    } else if (pageStart > 1 && num < pageStart + 3) {
      setPageStart(pageStart - 1)
    }
    setCurrentPage(num)
  }

  return <div>
    {size > perPage? <div>

      {currentPage > 1? <span>
        <span className="pagination-num" onClick={() => {changePage(1); setPageStart(1)}}>{"<<"}</span>
        <span className="pagination-num" onClick={() => changePage(currentPage - 1)}>{"<"}</span>
      </span> : ''}
      
      {pages.map(num => ( 
        <span
          key={num}
          className={`pagination-num${currentPage === num? ' selected-page' : ''}`}
          onClick={() => changePage(num)}
        >
          {num}
        </span>
      ))}

      {currentPage < pageCount? <span>
        <span className="pagination-num" onClick={() => changePage(currentPage + 1)}>{">"}</span>
        <span className="pagination-num" onClick={() => {changePage(pageCount); setPageStart(pageCount - (paginationLimit -1))}}>{">>"}</span>
      </span> : ''}

    </div> : ''}

    <div className="gallery-box">
      {selectedEntries.map(entry => {
        return <div key={entry} className="gallery-image" style={{ background: `url(${entry}) center / cover`, width: '200px', height: '200px'}}>
          {/* <img src={entry} alt={entry.slice(0, 50)}/> */}
        </div>
      })}
    </div>

    {size > perPage? <div>

      {currentPage > 1? <span>
        <span className="pagination-num" onClick={() => {changePage(1); setPageStart(1)}}>{"<<"}</span>
        <span className="pagination-num" onClick={() => changePage(currentPage - 1)}>{"<"}</span>
      </span> : ''}

      {pages.map(num => ( 
        <span
          key={num}
          className={`pagination-num${currentPage === num? ' selected-page' : ''}`}
          onClick={() => changePage(num)}
        >
          {num}
        </span>
      ))}

      {currentPage < pageCount? <span>
        <span className="pagination-num" onClick={() => changePage(currentPage + 1)}>{">"}</span>
        <span className="pagination-num" onClick={() => {changePage(pageCount); setPageStart(pageCount - (paginationLimit -1))}}>{">>"}</span>
      </span> : ''}
    
    </div> : ''}

    <div className='spacer'></div>
  </div>
}

export default Gallery;