import './App.css';
import Gallery from './Gallery'

function App() {
  return (
    <div className="App">
      <div class="draw-ketchup-header">
        <img src="https://us-a.wayin.com/images/2184/2d951d85-8337-412c-b3f5-5657342f23b6/HeinzWordmark_RedOverlay.png" alt="Heinz" class="head-logo" />
        <h1 class="draw-ketchup-big">DRAW KETCHUP</h1>
      </div>

      {/* <iframe title="youtube-embed" class="video-frame" width="560" height="315" src="https://www.youtube.com/embed/APoGHH1Ns2M" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
      <iframe title="youtube-embed" class="video-frame" src="https://www.youtube.com/embed/APoGHH1Ns2M?rel=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

      <Gallery />
    </div>
  );
}

export default App;
